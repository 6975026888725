<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="filter">
                <div>
                    <div class="radioGroup">
                        <div class="radioItem" v-for="(item, index) in radioList" :key="index" :class="{active: index == currentIndex}" @click="currentIndex = index">{{item}}</div>
                    </div>
                </div>
                <div>
                    <a-select default-value="0" style="width: 120px" @change="handleChange">
                        <a-select-option value="0">
                            全部
                        </a-select-option>
                        <a-select-option value="1">
                            已解决
                        </a-select-option>
                        <a-select-option value="2">
                            未解决
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="filter" style="padding:0 20px 10px">
                <div> {{radioList[currentIndex]}}（5条） </div>
                <div>
                    <a-button type="primary" class="mr10px"  @click="visible = true"> 一键回复 </a-button>
                    <!-- :disabled="!selectedRowKeys.length" -->
                    <a-button @click="deleteItem">删除</a-button>
                </div>
            </div>
            <a-table
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :columns="column"
                :data-source="data"
            >
                <template slot="operation">
                    <span class="csP" style="color:#7ebae5">回复</span>
                </template>
            </a-table>
        </div>
        <a-modal v-model="visible" title="回复" ok-text="确认" cancel-text="取消" @ok="hideModal">
            <a-tag color="#b5b5b5" class="mb20x" @click="areaval='已收到您的建议，将为您尽快解决'"> 已收到您的建议，将为您尽快解决 </a-tag>
             <a-textarea
                v-model="areaval"
                :autosize="{ minRows: 3, maxRows: 5 }"
                placeholder="请输入回复内容"
            />
        </a-modal>
    </div>
</template>
<script>
const columns = [
  {
    title: '学生姓名',
    dataIndex: 'name',
  }, {
    title: '电话',
    dataIndex: 'age',
  }, {
    title: '微信号',
    dataIndex: 'wechart',
  }, {
    title: '状态',
    dataIndex: 'status',
  }, {
    title: '反馈内容',
    dataIndex: 'content1',
  }, {
    title: '上新内容',
    dataIndex: 'content2',
  }, {
    title: '提问内容',
    dataIndex: 'content3',
  },{
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
    name: 'backlog',
    data(){
        this.radioList=['建议上新', '建议反馈', '课程答疑']
        return{
            currentIndex: 0,
            columns,
            data: [],
            selectedRowKeys: [],
            visible: false,
            areaval: ''
        }
    },
    computed: {
        column(){
            if(this.currentIndex == 0){
                return this.columns.filter(el=>el.title!='提问内容' && el.title!='反馈内容')
            }else if(this.currentIndex == 1){
                return this.columns.filter(el=>el.title!='提问内容' &&  el.title!='上新内容')
            }else{
                return this.columns.filter(el=>el.title!='上新内容' && el.title!='反馈内容')
            }
        }
    },
    methods:{
        handleChange(){

        },
        onSelectChange(){

        },
        deleteItem(){
             this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    console.log('yes')
                },
            });
        },
        hideModal(){

        }
    }
}
</script>
<style lang="less" scoped>

</style>